export const basepath = window.location.pathname.split("/")[1];

const events = {
    "testing": {   
        eventId: "4542b4d7-319f-4bb3-992b-5c81b25d97ca",
        questionId: "2f87f4c4-3bf9-4139-9147-82aaede6a466",
    },
    "game1": {
        eventId: "b29c132b-de8e-4ab5-af3c-8a8dec45a9fe",
        questionId: "4dad277a-7b91-44fa-bc06-7eadb22110cb"
    },
    "game2": {
        eventId: "347972a3-0a97-406c-bcdd-d1912b332718",
        questionId: "254d062e-cc54-4a06-b2cb-569711879fa3"
    },
    "game3": {
        eventId: "d4caeed2-186c-4434-a5e1-7963472f22cf",
        questionId: "d2abfa4f-5c5f-40d2-9077-6db85f283330"
    },
    "game4": {
        eventId: "ef42af2e-e41b-4c4b-9f9b-8a39ab437fb3",
        questionId: "5be50e55-0b25-49ce-b210-b51ead32cad5"
    },
    "game5": {
        eventId: "6a6e38d8-edfc-43c7-94fd-271176cf871c",
        questionId: "82daa26e-2ad3-4f5f-83c0-03439d8b65da"
    },
    "game6": {
        eventId: "8a944cca-d9ad-4fc7-9622-0ad231481a1f",
        questionId: "f1640afc-23c9-4ea5-b6ea-2701da55d1f3"
    },
    "game7": {
        eventId: "42da8cb0-249c-4aa6-97c6-f3911b73946a",
        questionId: "d8015b9f-a944-4be4-8ba0-c5ff72f8a3a8"
    },
    "game8": {
        eventId: "f9def0c7-68a4-4193-ae87-7b5077d1f8cc",
        questionId: "1cf8aa7e-2173-4d15-b734-d2032fb86398"
    },
    "game9": {
        eventId: "9fa01a7d-804f-4640-ad68-ad5a454b5f2b",
        questionId: "c672202c-6dcc-4f4f-bde7-0a60b7eb7324"
    },
    "game10": {
        eventId: "ab65b6ce-4616-45a1-929d-4127d60d8e4d",
        questionId: "5728149f-b8c3-4907-936d-27a36a35489d"
    },
    "game11": {
        eventId: "15c8fb05-d72b-48d9-ad61-44d5cd36db5e",
        questionId: "d78721d4-94d6-4fa1-88c5-68ddc532d2b2"
    },
    "game12": {
        eventId: "4cee70b1-6ed6-45c2-afa3-a995b1b9a669",
        questionId: "6593b80c-cc2e-465a-997c-9289584c1bf2"
    },
};

export const isValidEvent = (basepath: string): boolean => {
    if (!basepath) {
        return false;
    }

    return events[basepath.toLowerCase()] !== undefined;
}

export const eventDetails = (basepath: string): { eventId: string, questionId: string} => {
    if (!isValidEvent(basepath)) {
        return undefined;
    }
    return events[basepath.toLowerCase()];
}